// @ts-strict-ignore
import { ReactNode, useEffect, useId, useRef, useState } from 'react';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import styled, { css } from 'styled-components';
import { throttle } from 'lodash';
import i18n from 'src/utils/translate';
import { SearchBarIconButton } from 'styles-js/buttons';
import { fetchCommunities, searchCommunities } from 'src/modules/shared/modal/components/signup/actions';
import { useDispatch, useSelector } from 'src/store/store';
import Icon from 'src/components/icon';
import { getPublic } from 'src/utils/api';

type Props = {
  error?: string;
  label: ReactNode;
  placeholder?: string;
}
export default function FindCommunitySearchBar({
  error,
  label,
  placeholder = '',
}: Props) {
  const dispatch = useDispatch();
  const { isNoResults } = useSelector(({ modals }) => modals.signupOnboarding);
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);

  const id = useId();

  useEffect(() => {
    if (value.trim() === '' || value === null) {
      dispatch(fetchCommunities());
    }
  }, [value, dispatch]);

  const onSubmit = async (e) => {
    e.preventDefault();
    inputRef.current?.blur();
    setIsFocus(false);
    dispatch(searchCommunities(value));
  };

  const inputProps = {
    className: 'search-bar__input',
    placeholder,
    type: 'text',
    value,
    onBlur: () => setIsFocus(false),
    onChange: (_, { newValue }) => setValue(newValue),
    onFocus: () => setIsFocus(true),
  };

  const renderInputComponent = (props) => {
    const inputContainerClassName = classNames({
      'search-bar__container': true,
      'search-bar__container--focused': isFocus && !error,
      'search-bar__container--error': error,
    });
    return (
      <>
        <div className={inputContainerClassName}>
          <input
            aria-label={i18n.t('Start a search')}
            ref={inputRef}
            {...props} // eslint-disable-line react/jsx-props-no-spreading
          />
          <SearchBarIconButton disabled={!value} type="submit">
            <Icon icon="search" />
            <span className="search-bar__submit-text">{ i18n.t('Search') }</span>
          </SearchBarIconButton>
        </div>
        {error && <ValidationError>{error}</ValidationError>}
      </>
    );
  };

  const renderSuggestion = (text) => {
    // queries with special characters (e.g. 'Pregnancy (difficulties)') were breaking the RegExp so they are now escaped
    const escapedHighlight = value.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
    return (
      <>
        {parts.map((part) => (
          <Suggestion isHighlighted={part.toLowerCase() === value.toLowerCase()} key={part}>
            {part}
          </Suggestion>
        ))}
      </>
    );
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const onSuggestionSelected = (e, { suggestion }) => {
    setValue(suggestion);
    onSubmit(e);
  };

  const onSuggestionsFetchRequested = throttle(async ({ value = '' }) => {
    if (value.length <= 1) return;
    const { data } = await getPublic(`search/autocomplete?q=${value}`);
    setSuggestions(data.slice(0, 10).map((suggestion) => suggestion.toLowerCase())); // TODO: remove toLowerCase when the b/e is fixed
  }, 500);

  return (
    <>
      {label}
      <form onSubmit={onSubmit}>
        <Autosuggest
          getSuggestionValue={suggestion => suggestion}
          id={id}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
          renderSuggestion={renderSuggestion}
          suggestions={suggestions}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        />
      </form>
      {isNoResults && (
        <EmptyState data-testid="signup-find-communities-empty-state">
          <p>{i18n.t('0 public communities')}</p>
          <p>{i18n.t(`Sorry, we don't have any public communities matching `) + value}</p>
          <p>{i18n.t('Try broadening your search results to get more results')}</p>
        </EmptyState>
      )}
    </>
  );
}

const EmptyState = styled.div`
  margin-top: 25px;
  p {
    margin-bottom: 10px;
  }
`;

const ValidationError = styled.div`
   color: ${({ theme }) => theme.colorError};
   padding: 10px 0 20px;
`;

const Suggestion = styled.span<{ isHighlighted?: boolean }>`
  ${({ isHighlighted }) => isHighlighted && css`
    font-weight: bold;
  `};
`;
