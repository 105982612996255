import { NextApiRequest } from 'next';
import { PageContext } from 'src/constants/types';
import { setServerSession, unsetServerSession } from 'src/modules/shared/auth/actions';
import { fetchCurrentUser, setIsAndroidUser } from 'src/modules/shared/context/actions';
import { getUserAgent } from 'src/utils/userAgent';

export async function setSession({ req, store: { dispatch, getState } }: PageContext) {
  if (!req) return;

  await dispatch(setServerSession((req as NextApiRequest).cookies?.huSessID));

  if (!getState().context?.sessionId?.endsWith(':00000000-0000-0000-0000-000000000000')) {
    await dispatch(fetchCurrentUser());
  }

  await dispatch(setIsAndroidUser(getUserAgent(req).includes('Android')));
}

export async function clearSessionId({ req, store: { dispatch } }: PageContext) {
  if (!req) return;
  await dispatch(unsetServerSession());
}
