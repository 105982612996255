import { ChangeEvent, FocusEvent } from 'react';
import styled, { css } from 'styled-components';
import { text, textLight, textSmall } from 'styles-js/mixins/typography';

type Props = {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  id: string;
  maxLength?: number;
  placeholder?: string;
  showCharacterCount?: boolean;
  showErrors?: boolean;
  type?: string;
  validationErrors?: string[];
  value?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
export default function TextInput({
  autoComplete = 'on',
  autoFocus = false,
  className = '',
  dataTestId = '',
  disabled = false,
  id,
  maxLength,
  placeholder,
  showCharacterCount,
  showErrors = true,
  type,
  validationErrors = [],
  value,
  onBlur = () => {},
  onChange = () => {},
}: Props): JSX.Element {

  return (
    <Container>
      <Input
        $hasError={showErrors && validationErrors.length > 0}
        $showCharacterCount={showCharacterCount}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={className}
        data-testid={dataTestId}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        name={id}
        placeholder={placeholder}
        type={type}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
      {showCharacterCount && maxLength && <CharacterCount maxLength={maxLength} value={value} />}
      <ValidationErrors>
        {showErrors && validationErrors.map((error) => <li data-testid="validation-warning" key={error}>{error}</li>)}
      </ValidationErrors>
    </Container>
  );
}

export function CharacterCount({ value, maxLength }: { value?: string; maxLength: number }): JSX.Element {
  return (
    <Count>{`${value?.length ?? 0} / ${maxLength}`}</Count>
  );
}

const Container = styled.div`
  position: relative;
  .icon {
    position: absolute;
    top: 17px;
    left: 10px;
    color: ${({ theme }) => theme.colorGrey};
    font-size: 20px;
    z-index: 100;
  }
`;

const Input = styled.input<{ $hasError?: boolean; $showCharacterCount?: boolean; }>`
  ${text}
  box-sizing: border-box;
  width: 100%;
  height: 38px;
  padding: 5px 12px 7px 12px;
  background-color: ${({ theme }) => theme.colorWhite};
  background-image: none;
  border: ${({ theme }) => theme.greyLine};
  border-radius: 0;
  margin: 5px 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  appearance: none;

  &:disabled {
    background-color: ${({ theme }) => theme.colorGreyLight};
    color: ${({ theme }) => theme.colorGreyDark};
  }
  &::placeholder {
    ${textLight}
  }

  ${({ $hasError }) => $hasError && css`
    border-color: ${({ theme }) => theme.colorError};
  `}
  ${({ $showCharacterCount }) => $showCharacterCount && css`
    padding-right: 7ch;
  `}
`;

export const ValidationErrors = styled.ul`
  padding-left: 23px;
  margin-bottom: 0;
  margin-top: 0;

  li {
    ${textSmall}
    color: ${({ theme }) => theme.colorError};
    text-align: initial;
    margin-bottom: 12px;
    margin-top: 3px;

    a {
      color: ${({ theme }) => theme.colorError};
      text-decoration: underline;
    }
  }
`;

export const Textarea = styled(Input)<{ as: 'textarea' }>`
  height: auto;
`;

const Count = styled.span`
  ${textLight}
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 12px 7px;
  margin: 5px 0;
  height: 38px;
  display: flex;
  align-items: center;
`;
