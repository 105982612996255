// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import router from 'next/router';
import { ActionType, ComposeParticipant } from 'src/modules/messages/types';
import { del, get, getServerSideConfig, post, put } from 'src/utils/api';
import { setAlert } from 'src/components/alert/actions';
import i18n from 'src/utils/translate';
import { MuteUserConfirmation } from 'src/modules/messages/messageMuteModal';
import { ArchiveMessageSuccess } from 'src/modules/messages/messageArchiveModal';
import { parseString } from 'src/utils/parserText';
import { ReduxState } from 'src/store/store';

export function fetchMessages(pageNumber: number) {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: ActionType.FETCH_MESSAGES });
    try {
      const { data } = await get(`private/messages?pageNumber=${pageNumber}`, getServerSideConfig(getState().context.sessionId));
      dispatch({ type: ActionType.FETCH_MESSAGES_SUCCESS, threads: data, pageNumber });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: ActionType.FETCH_MESSAGES_FAILURE });
    }
  };
}

export function hideMessage(id: number) {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: ActionType.HIDE_MESSAGE });
    try {
      await post('private/messages/hide', { messageIds: [id] });
      dispatch({ type: ActionType.HIDE_MESSAGE_SUCCESS, id });
      dispatch(fetchMessages(getState().pages.messages.pageNumber));
      dispatch(setAlert(<ArchiveMessageSuccess id={id} />, 'success', 6000));
    } catch (ex) {
      captureException(ex);
      dispatch({ type: ActionType.HIDE_MESSAGE_FAILURE });
      dispatch(setAlert());
    }
  };
}

export function hideMessageUndo(id: number) {
  return async (dispatch, getState: () => ReduxState) => {
    try {
      await post('private/messages/undo-hide', { messageIds: [id] });
      dispatch(fetchMessages(getState().pages.messages.pageNumber));
      dispatch(setAlert(i18n.t('Message restored'), 'success'));
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert());
    }
  };
}

export function muteUser(currentUserId: number, mutedUserId: number, muteUsername: string) {
  return async (dispatch, getState: () => ReduxState) => {
    try {
      await post(`private/users/${currentUserId}/mute-user`, { mutedUserId });
      dispatch(fetchMessages(getState().pages.messages.pageNumber));
      dispatch(setAlert(<MuteUserConfirmation username={muteUsername} />, 'success', 6000));
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert());
    }
  };
}

export function leaveGroupMessage(messageId: number) {
  return async (dispatch, getState: () => ReduxState) => {
    try {
      await del(`private/messages/${messageId}/participant`);
      dispatch(fetchMessages(getState().pages.messages.pageNumber));
      dispatch(setAlert(i18n.t('You left the conversation.'), 'success'));
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert());
    }
  };
}

export function setMessageComposeDetails({ participants, subject }: { participants: ComposeParticipant[]; subject?: string; }) {
  return (dispatch) => dispatch({ type: ActionType.SET_MESSAGE_COMPOSE_DETAILS, participants, subject: subject?.trim() });
}

export function sendMessage({ body, participantIds, subject, threadId }: { body: string; participantIds?: number[]; subject?: string; threadId?: number; }) {
  return async (dispatch) => {
    try {
      const params = { body, participantIds, ...(subject && { subject }), threadId };
      const { data: { threadId: responseThreadId } } = await post('private/message/send', params);
      if (!threadId) {
        router.replace('/messages/thread', `/messages/${responseThreadId}`);
      }
      await dispatch(fetchThread({ threadId: threadId || responseThreadId }));
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert());
    }
  };
}

type FetchByThread = {
  fromId?: number;
  number?: number;
  threadId: number;
  userId?: never;
  username?: never;
};
type FetchByUser = {
  fromId?: never;
  number?: never;
  threadId?: never;
  userId: number;
  username?: string;
}
export function fetchThread({ fromId, number, threadId, userId, username }: FetchByThread | FetchByUser) {
  return async (dispatch, getState: () => ReduxState) => {
    dispatch({ type: ActionType.FETCH_MESSAGE });
    try {
      const { data } = await get('private/messages/thread', { params: { messageId: threadId, userId, fromId, number }, ...getServerSideConfig(getState().context.sessionId) });
      const messages = data.messages.map((message) => ({ ...message, body: parseString(message.body, []) }));
      const thread = {
        ...data,
        messages,
      };
      dispatch({ type: ActionType.FETCH_MESSAGE_SUCCESS, thread });
      if (userId && thread.threadId) {
        router.push('/messages/thread', `/messages/${thread.threadId}`);
      }
    } catch (ex) {
      if (ex.response?.status === 404) { // no existing thread
        if (username) {
          router.push('/messages/thread', `/messages/compose/${username}`);
        }
      } else {
        dispatch({ type: ActionType.FETCH_MESSAGE_FAILURE });
        router.replace('/messages');
        captureException(ex);
        dispatch(setAlert());
      }
    }
  };
}

export function fetchPreviousMessages({ threadId, fromId }: { threadId: number; fromId: number; }) {
  return async (dispatch, getState: () => ReduxState) => {
    try {
      if (threadId === fromId) { // no more messages to fetch
        return;
      }
      dispatch({ type: ActionType.FETCH_PREVIOUS_MESSAGES });
      const { data } = await get('private/messages/thread', { params: { messageId: threadId, fromId }, ...getServerSideConfig(getState().context.sessionId) });
      const messages = data.messages.map((message) => ({ ...message, body: parseString(message.body, []) }));
      dispatch({ type: ActionType.FETCH_PREVIOUS_MESSAGES_SUCCESS, messages });
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert());
    }
  };
}

type ReportMessage = {
  messageId: number;
  comment?: string;
  reason: string;
  onSuccess: () => void;
}
export function reportMessage({ messageId, comment, reason, onSuccess }: ReportMessage) {
  return async (dispatch) => {
    try {
      const params = { reason, ...(comment && { comment }) };
      await post(`private/messages/${messageId}/report`, params);
      onSuccess();
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert());
    }
  };
}

export function editSubject(threadId: number, subject: string) {
  return async (dispatch) => {
    try {
      await put(`private/messages/thread/${threadId}`, { subject: subject.trim() });
      dispatch(fetchThread({ threadId }));
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert());
    }
  };
}
