// @ts-strict-ignore
import Router from 'next/router';
import i18n from 'src/utils/translate';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import { removeRecipientFromUrl } from 'src/utils/router';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import { fetchNHSTagContent } from 'src/modules/tag/actions';
import actionTypes from 'src/modules/shared/modal/actionTypes';
import { updatePollLock, updatePostLock } from 'src/modules/community/singlePost/actions/actions';
import { signup, signupWithSocial } from 'src/modules/shared/auth/actions';
import { ModalType } from 'src/constants/modalTypes';
import { SignupFlow } from 'src/modules/shared/modal/components/signup/onboardingModal';
import { ModalProps as DefaultModalProps } from 'src/modules/shared/modal/components/defaultModal';
import { Community, PostType } from 'src/constants/types';
import { LOCK } from 'src/constants/postCommons';
import { LinkButton } from 'styles-js/buttons';
import { ReduxState } from 'src/store/store';

export type ModalProps = {
  [key: string]: unknown;
}

export function showModal({
  modalType,
  modalProps = {},
}: { modalType: ModalType, modalProps?: ModalProps }) {
  return { type: actionTypes.MODAL_SET, modalType, modalProps };
}

export function setModalProps(modalProps: ModalProps) {
  return { type: actionTypes.MODAL_SET_PROPS, modalProps };
}

export function closeModal() {
  return { type: actionTypes.MODAL_UNSET };
}

export type ShowSignupModal = {
  community?: Community | null;
  defaultEmail?: string;
  onSignupSuccess?: () => void;
  redirectPage?: () => void;
  signupFlow?: SignupFlow;
}
export function showSignupModal({
  community,
  defaultEmail = '',
  onSignupSuccess,
  redirectPage,
  signupFlow,
}: ShowSignupModal = {}) {
  return showModal({
    modalType: ModalType.Signup,
    modalProps: {
      community,
      defaultEmail,
      onSignupSuccess,
      redirectPage,
      signupFlow,
    },
  });
}

type ShowLoginModal = {
  noClose?: boolean;
  onLoginSuccess?: () => void;
  username?: string;
  clickMetaData?: { [key: string]: string };
}

export function showLoginModal({
  noClose = false,
  onLoginSuccess,
  username = '',
  clickMetaData,
}: ShowLoginModal = {}) {
  trackFootfallEvent(Event.Clicked, { clickType: 'login-modal-viewed', clickMetaData });

  return showModal({
    modalType: ModalType.Login,
    modalProps: {
      noClose,
      onLoginSuccess,
      username,
    },
  });
}

export type AuthData = {
  email: string;
  password: string;
}
export type SocialAuthData = {
  email?: never;
  password?: never;
  googleUserId?: string;
  googleToken?: string;
}
type ShowOnboarding = {
  authData: AuthData;
  onSignupSuccess?: () => void;
  isCommunitySignup?: boolean;
  redirectPage?: () => void;
}
export function showOnboarding({
  authData: { email, password },
  onSignupSuccess,
  isCommunitySignup = false,
  redirectPage,
}: ShowOnboarding) {
  return async (dispatch, getState: () => ReduxState) => {
    const signupFlow = getState().modal.modalProps.signupFlow || isCommunitySignup && SignupFlow.Community;
    await dispatch(closeModal());
    return dispatch(showModal({
      modalType: ModalType.Onboarding,
      modalProps: {
        signupFlow,
        redirectPage,
        signupUser: async ({ username }: { username: string }) => {
          await dispatch(signup({ password, username, email, isCommunitySignup }));
          if (getState().context.currentUser && onSignupSuccess) {
            onSignupSuccess();
          }
        },
      },
    }));
  };
}

type ShowOnboardingSocial = {
  authData: SocialAuthData;
  onSignupSuccess?: () => void;
  isCommunitySignup?: boolean;
  redirectPage?: () => void;
}
export function showOnboardingSocial({
  authData,
  onSignupSuccess,
  isCommunitySignup = false,
  redirectPage,
}: ShowOnboardingSocial) {
  return async (dispatch, getState: () => ReduxState) => {
    const signupFlow = getState().modal.modalProps.signupFlow || isCommunitySignup && SignupFlow.Community;
    await dispatch(closeModal());
    return dispatch(showModal({
      modalType: ModalType.Onboarding,
      modalProps: {
        isSocialSignup: true,
        signupFlow,
        redirectPage,
        signupUser: async ({ username }: { username: string }) => {
          await dispatch(signupWithSocial({ ...authData, username }, authData.googleUserId ? 'Google' : 'Facebook', !!isCommunitySignup));
          if (getState().context.currentUser && onSignupSuccess) {
            onSignupSuccess();
          }
        },
      },
    }));
  };
}

export function showExistingLoginSocialModal(authData, onLoginSuccess) {
  return showModal({
    modalType: ModalType.ExistingLoginSocial,
    modalProps: {
      authData,
      onLoginSuccess,
    },
  });
}

export function showGoogleAdsModal(content: string) {
  return (dispatch, getState: () => ReduxState) => {
    // BB-1274: Don't overwrite existing modal!
    const { modal: { modalType } } = getState();
    if (modalType) return;

    return dispatch(showModal({
      modalType: ModalType.GoogleAds,
      modalProps: { content },
    }));
  };
}

export function showTakeProfileSurveyModal() {
  trackFootfallEvent(Event.ProfileSurvey, { clickType: 'survey-param' });
  return showModal({ modalType: ModalType.TakeProfileSurvey });
}

export function showTurnOffRepliesModal(onTurnOff) {
  return (dispatch, getState: () => ReduxState) => {
    const state = getState();
    const communitySlug = state.context.currentCommunity?.slug;
    const { id, postTypeId } = state.pages.singlePost.post;
    const modalProps: DefaultModalProps = {
      acceptButtonText: i18n.t('Turn off replying'),
      cancelButtonText: i18n.t('Cancel'),
      children: (
        <p>
          {i18n.t('This will prevent users from creating or editing replies and the author of the post will be notified that replying has been disabled.')}<br />
          <LinkButton onClick={() => helpScoutArticle('36-turn-off-replying-on-posts')}>{i18n.t('Learn more')}</LinkButton>
        </p>
      ),
      title: i18n.t('Are you sure you want to do this?'),
      onAccept: () => {
        dispatch(postTypeId === PostType.Poll ? updatePollLock(id, communitySlug, LOCK) : updatePostLock(id, communitySlug, LOCK));
        onTurnOff();
      },
    };
    return dispatch(showModal({ modalType: ModalType.Default, modalProps }));
  };
}

const OPEN_SIGNUP_NORMALLY = '1';
const INACTIVE_USER_SURVEY = '2';
const SURVEY = '3';
const COVID_19_FEATURED_POST_MODAL = '19';
const PROFILE_SURVEY = 'profile';

export const handleQueryParams = (queryParams, footfallSection = '') => {
  return async (dispatch, getState: () => ReduxState) => {
    if (!queryParams) return;
    // To fix: URLs with hash don't have the params below so opening the welcome back modal doesn't work
    const { utm_source, popup, collector, recipient, survey } = queryParams;
    const recipientParam = recipient;
    const { context: { currentUser, currentCommunity } } = getState();
    // exception, other modals take priority, we prevent double footfall events by checking the route
    const isEditProfilePage = Router.pathname === '/profile/edit';

    if (popup === COVID_19_FEATURED_POST_MODAL) {
      await dispatch(fetchNHSTagContent('coronavirus-covid-19', 'conditions'));
      dispatch(showModal({
        modalType: ModalType.NHSTagContent,
        modalProps: {
          tagName: 'Coronavirus (COVID-19)',
        },
      }));
      return;
    }

    if (popup === INACTIVE_USER_SURVEY) {
      dispatch(showModal({
        modalType: ModalType.Iframe,
        modalProps: {
          iframe: {
            src: `https://www.smartsurvey.co.uk/s/O6STRR/?id=${recipient}`,
            height: '605px',
          },
        },
      }));
      return;
    }

    if (popup === SURVEY) {
      dispatch(showModal({
        modalType: ModalType.Iframe,
        modalProps: {
          iframe: {
            src: `https://surveys.healthunlocked.com/s/${collector}/?id=${recipient}`,
            height: '605px',
          },
        },
      }));
      return;
    }

    if (popup in ModalType) {
      dispatch(showModal({ modalType: popup }));
    }

    if (currentUser) {
      if (recipientParam) removeRecipientFromUrl();

      // popup=profile used in featured posts, survey=true in emails
      if ((survey === 'true' || popup === PROFILE_SURVEY) && !isEditProfilePage) {
        dispatch(showTakeProfileSurveyModal());
        return;
      }

      return;
    }

    // Only for visitors
    if (utm_source === 'notification' || utm_source === 'iosapp') {
      dispatch(showLoginModal({ noClose: true, username: recipientParam }));
      if (recipientParam) {
        // delay removing param to make sure we display username in login modal
        setTimeout(() => removeRecipientFromUrl(), 1500);
      }

    } else if (popup === OPEN_SIGNUP_NORMALLY) {
      trackFootfallEvent(Event.Clicked, { clickType: 'sign-up', clickMetadata: { section: footfallSection } });
      dispatch(showSignupModal({ community: currentCommunity }));
    }
  };
};
