// @ts-strict-ignore
import Username from 'src/modules/shared/modal/components/signup/onboardingScreens/username';
import AcceptTerms from 'src/modules/shared/modal/components/signup/onboardingScreens/acceptTerms';
import ResearchOptIn from 'src/modules/shared/modal/components/signup/onboardingScreens/researchOptIn';
import CheckEmail from 'src/modules/shared/modal/components/signup/onboardingScreens/checkEmail';
import FindCommunities from 'src/modules/shared/modal/components/signup/onboardingScreens/findCommunities';
import Welcome from 'src/modules/shared/modal/components/signup/onboardingScreens/welcome';
import { Screens } from 'src/modules/shared/modal/components/signup/onboardingModal';

type Props = {
  currentScreen: Screens;
  username: string;
  setCurrentScreen: (screen: Screens) => void;
  setUsername: (username: string | null) => void;
};
export default function Screen({ currentScreen, setCurrentScreen, username, setUsername }: Props) {
  switch (currentScreen) {
    case Screens.Username:
      return <Username setCurrentScreen={setCurrentScreen} setUsername={setUsername} username={username} />;
    case Screens.AcceptTerms:
      return <AcceptTerms setCurrentScreen={setCurrentScreen} username={username} />;
    case Screens.AcceptResearch:
      return <ResearchOptIn setCurrentScreen={setCurrentScreen} />;
    case Screens.CheckEmail:
      return <CheckEmail />;
    case Screens.FindCommunities:
      return <FindCommunities setCurrentScreen={setCurrentScreen} />;
    case Screens.Welcome:
      return <Welcome />;
    default: return null;
  }
}
